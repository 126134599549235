import { useEffect } from "react";
import { Route, Redirect } from "react-router-dom";

const PrivateRoute = ({ children, ...rest }: any) => {
  const access_token = sessionStorage.getItem("accessToken");
  console.log({ access_token })
  useEffect(() => {
    if (!access_token) window.location.replace("/")
  }, [access_token])
  return (
    <Route
      {...rest}
      render={() => (access_token ? children : <Redirect to="/" />)}
    />
  );
};

export default PrivateRoute;
