import React, { useState, Fragment, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { useParams } from "react-router-dom";
import styles from "./Settlement.module.scss";
import PaginationTable from "../../../components/table/pagination-table";
import {
  ColumnBusiness,
  BusinessData,
} from "../../../types/TablesTypes/Business";
import DeleteIcon from "../../../assets/images/Icons/delete.svg";
import {
  ColumnSettlements,
  SettlementsData,
} from "../../../types/TablesTypes/Settlements";
import MenuOption from "../../../components/MenuOption/MenuOption";
import StatusView from "../../../components/StatusView/StatusView";
import TableHeader from "../../../components/TableHeader/TableHeader";
import usersApi from "../../../api/users";
import settlementApi from "../../../api/settlementGroups";
import subsidiariesApi from "../../../api/subsidiaries";
import useApi from "../../../hooks/useApi";
import client from "../../../api/client";
import { numberWithCommas } from "../../../utils/formatNumber";
import { openToastAndSetContent } from "../../../redux/actions/toast/toastActions";
import {
  ToastSuccessStyles,
  ToastErrorStyles,
} from "../../../constants/toastStyles";
import {
  closeLoader,
  openLoader,
} from "../../../redux/actions/loader/loaderActions";

const SettlementGroupsDetails = () => {
  const { id } = useParams<{ id: any }>();
  const [tableRow, setTableRow] = useState<any[]>();
  const [value, setValue] = useState("");
  const [subsidiariesDetails, setSubsidiariesDetails] = useState<any>();
  const [settlementGroupDetails, setSettlementGroupDetails] = useState<any>();
  const getSettlementApi = useApi(settlementApi.getSettlementGroups);
  const [isLoading, setIsLoading] = useState(false);
  const getSubsidiariesApi = useApi(subsidiariesApi.getSubsidiaries);

  const getSettlementGroupsApi = useApi(settlementApi.getSettlementGroups);

  const fetchSettlementGroups = async () => {
    getSettlementGroupsApi.request();
  };
  const settlementGroups = getSettlementGroupsApi?.data?.items;

  useEffect(() => {
    dispatch(openLoader());
    setIsLoading(true);
    client
      .get(`/settlement/group/view?id=${id}`)
      .then((res: any) => {
        setSettlementGroupDetails(res?.data?.data);
        setIsLoading(false);
        dispatch(closeLoader());
      })
      .catch((error) => {
        dispatch(closeLoader());
        setIsLoading(false);
        if (error.response) {
          const message = error?.response?.data?.message;
          dispatch(
            openToastAndSetContent({
              toastStyles: ToastErrorStyles,
              toastContent: message,
            })
          );
        } else if (error.request) {
          console.log(error.request);
        } else {
          console.log("Error", error.message);
        }
      });
  }, []);

  const dispatch = useDispatch();

  const fetchSettlements = async () => {
    getSettlementApi.request();
  };
  useEffect(() => {
    fetchSettlements();
  }, []);

  useEffect(() => {
    dispatch(openLoader());
    setIsLoading(true);
    client
      .get(`subsidiary/all/paginated?subsidiarygroup=${settlementGroups?.name}`)
      .then((res: any) => {
        // console.log("paginated", res?.data);
        setSubsidiariesDetails(res?.data);
        setIsLoading(false);
        dispatch(closeLoader());
      })
      .catch((error) => {
        dispatch(closeLoader());
        setIsLoading(false);
        if (error.response) {
          const message = error?.response?.data?.message;
          dispatch(
            openToastAndSetContent({
              toastStyles: ToastErrorStyles,
              toastContent: message,
            })
          );
        } else if (error.request) {
          console.log(error.request);
        } else {
          console.log("Error", error.message);
        }
      });
  }, []);

  const deleteGroup = async () => {
    try {
      const data: any = await client.delete(
        `subsidiary/all/paginated?businessName/${settlementGroups?.businessName}`
      );
      const message = data?.data?.message;
      fetchSettlements();
      dispatch(
        openToastAndSetContent({
          toastStyles: ToastSuccessStyles,
          toastContent: message,
        })
      );
    } catch (error: any) {
      const message = error?.response?.data?.message;
      dispatch(
        openToastAndSetContent({
          toastStyles: ToastErrorStyles,
          toastContent: message,
        })
      );
    }
  };

  const dataBusinesses = () => {
    const tempArr: BusinessData[] = [];
    subsidiariesDetails?.data?.items
      ?.slice(0)
      .reverse()
      .forEach((settlement: any, index: number) => {
        return tempArr.push({
          id: settlement.id,
          name: settlement?.businessName,
          merchantId: settlement.merchantId,
          businessName: settlement?.businessName,
          businessType: settlement?.businessType,
          emailAddress: settlement?.email,
          countryName: settlement?.country,
          contactPerson: settlement?.country,
          date: settlement?.country,
          status: (
            <StatusView
              status={settlement?.status}
              green="Active"
              red="New"
              orange="Pending-Verification"
              blue="In-Review"
            />
          ),
          action: deleteGroup(),
        });
      });
    return tempArr;
  };

  useEffect(() => {
    setTableRow(dataBusinesses);
  }, [subsidiariesDetails?.items]);

  return (
    <Fragment>
      <TableHeader
        pageName="Settlement Group"
        data={subsidiariesDetails?.data?.items}
        dataLength={subsidiariesDetails?.data?.items?.length}
        value={value}
        setValue={setValue}
        back={true}
      />
      {getSettlementApi.loading ? (
        <div className={styles.loading} />
      ) : (
        <PaginationTable
          data={tableRow ? tableRow : []}
          columns={ColumnBusiness ? ColumnBusiness : []}
          emptyPlaceHolder="You currently do not have any data"
          value={value}
        />
      )}
    </Fragment>
  );
};

export default SettlementGroupsDetails;
