import React from 'react';

export interface RefundData {
	date: string;
	amount: string;
	businessName: string;
	merchantId: string;
	amountCharged: string;
	transactionReference?: string;
	channelMid?:string;
	originalTransactionDate?:string;
	refundReceipt?:string;
	customerName?:string;
	id?:number;
	status: any;
	// action: React.ReactNode
}

export const ColumnRefund = [
	{
		Header: 'Id',
		accessor: 'id',
	},
	{
		Header: 'Due date',
		accessor: 'date',
	},
	{
		Header: 'Merchant ID',
		accessor: 'merchantId',
	},
	{
		Header: 'Business name',
		accessor: 'businessName',
	},
	{
		Header: 'Customer name',
		accessor: 'customerName',
	},
	{
		Header: 'Amount refunded',
		accessor: 'amount',
	},
	{
		Header: 'Amount charged',
		accessor: 'amountCharged',
	},
	// {
	// 	Header: 'Order reference',
	// 	accessor: 'transactionReference',
	// },
	{
		Header: 'Original Transaction Date',
		accessor: 'originalTransactionDate',
	},
	{
		Header: 'Refund Receipt',
		accessor: 'refundReceipt',
	},
	{
		Header: 'Channel Mid',
		accessor: 'channelMid',
	},
	{
		Header: 'Status',
		accessor: 'status',
	},
	// {
	// 	Header: '',
	// 	accessor: 'action',
	// },
];
