import React, { useState, useEffect } from 'react';
import styles from './Navbar.module.scss';
import BrandLogo from '../../assets/images/Logos/paymentgateway.png';
// import { ReactComponent as BrandLogo } from '../../assets/images/Logos/paymentgateway.png';
import { ReactComponent as BellIcon } from '../../assets/images/Icons/bell.svg';
import { useSelector, useDispatch } from 'react-redux';
import { changeNavbar } from '../../redux/actions/navbar/navbarActions';
import { useHistory, useLocation } from 'react-router-dom';
import Divider from '@mui/material/Divider';
import ProfileIcon from '../../assets/images/Icons/profile2.svg';
import SignOutIcon from '../../assets/images/Icons/signout.svg';
import MenuIcon from '@mui/icons-material/Menu';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import ProfileButton from '../profilebutton/ProfileButton';
import NotificationButton from '../notificationButton/NotificationButton';
import ActionButton from '../actionButton/ActionButton';
import {
	dataAccount,
	dataDashboard,
	dataSettings,
	dataTransaction,
} from '../../utils/subMenu';
import { combinedMenu } from '../../utils/combinedMenu';
import { ReactComponent as Switch } from '../../assets/images/Icons/arrowForward.svg';

const NavBar = ({
	selectedMenu,
	selectedNav,
	setSelectedMenu,
	setSelectedNav,
	collapse,
	setCollapse,
}: {
	selectedMenu: number;
	selectedNav: number;
	setSelectedMenu: any;
	setSelectedNav: any;
	collapse: boolean;
	setCollapse: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
	const history = useHistory();
	const dispatch = useDispatch();
	const location = useLocation();
	const path = location.pathname.split("/");
	const search = location?.search ?? ""
	const splittedsearch = search.split("=");
	let splittedsearch1 = "";
	if (splittedsearch.length >= 2) splittedsearch1 = splittedsearch[1]
	let routeName = path[path.length - 1]
	console.log({ path, routeName, location, search, splittedsearch, splittedsearch1 })
	if (splittedsearch1) routeName = splittedsearch1;
	const [menuLink, setMenuLink] = React.useState<null | HTMLElement>(null);

	const openMenu = ({ url }: any) => {
		history.push(url);
	};

	const openSubmenu = (url: any) => {
		history.push(url);
	};

	const subMenuCombined = {
		...dataDashboard,
		...dataAccount,
		...dataTransaction,
		...dataSettings,
	};

	const [isDesktop, setDesktop] = useState(window.innerWidth > 920);

	const updateMedia = () => {
		setDesktop(window.innerWidth > 920);
	};

	useEffect(() => {
		window.addEventListener('resize', updateMedia);
		return () => window.removeEventListener('resize', updateMedia);
	});

	const handleClose = () => {
		setAnchorEl(null);
	};

	const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
	const open = Boolean(anchorEl);

	const handleClick = (event: any) => {
		setAnchorEl(event.currentTarget);
	};

	const goHome = (event: any) => {
		history.push('/dashboard');
		openMenu('/dashboard');
		setSelectedMenu(1);
		setSelectedNav(1);
		dispatch(changeNavbar('Overview'));
	};

	const handleAccount = () => {
		// history.push('/dashboard/settings/profile');
		setAnchorEl(null);
	};

	const signOutHandler = () => {
		sessionStorage.clear();
		window.location.href = '/';
		setSelectedMenu(1);
		setSelectedNav(1);
	};

	const [checked, setChecked] = useState<boolean>(false);
	const handleChecked = () => {
		setChecked(!checked);
	};
	const { navbarRoute } = useSelector((state) => state.navbarReducer);
	// console.log("Nav", { navbarRoute })
	// const _navbarRoute = navbarRoute[navbarRoute.length - 1]
	const _navbarRoute = routeName
	return (
		<div>
			{isDesktop ? (
				<div className={styles.container}>
					<div className={styles.navBar}>
						<div className={styles.route}>
							<p
								onClick={() => history.goBack()}
								style={{
									fontSize: '20px',
									cursor: "pointer"
								}}>
								←
							</p>{' '}
							<p>
								{_navbarRoute.toUpperCase()}
							</p>
						</div>

						<div className={styles.menu}></div>

						<div className={styles.activitySection}>
							<div>
								<ActionButton />
							</div>
							<div className={styles.notification}>
								{/* <BellIcon />
                {subsidiaryInfo?.data?.notifications?.length > 0 && ( */}
								{/* <div className={styles.notificationCount}> */}
								<div>
									<NotificationButton />
								</div>
								{/* </div>
                )} */}
							</div>
							<div>
								<ProfileButton />
							</div>
						</div>
					</div>
				</div>
			) : (
				<div className={styles.containerMobile}>
					<div className={styles.navBarMobile}>
						<div className={styles.swicth}>
							<div onClick={() => setCollapse(!collapse)} className={styles.swicthIcon}>
								<Switch />
							</div>
							<div className={styles.logoMobile} onClick={goHome}>
								<img src={BrandLogo} />
								{/* <BrandLogo /> */}
							</div>
						</div>

						<div className={styles.mobileMenu}>
							<MenuIcon
								aria-label='more'
								aria-controls='long-menu'
								aria-haspopup='true'
								onClick={handleClick}>
								<MoreVertIcon />
							</MenuIcon>
							<div className={styles.mobileMenuDiv}>
								<Menu
									id='fade-menu'
									MenuListProps={{
										'aria-labelledby': 'fade-button',
									}}
									anchorEl={anchorEl}
									open={open}
									onClose={handleClose}
								//  TransitionComponent={Fade}
								>
									{combinedMenu.map(({ id, menuName, url, submenu }) => (
										<div onClick={handleClose}>
											<div className={styles.accountMobile} key={id}>
												<div
													className={styles.mobileMenuItem}
													onClick={() => history.push(url)}>
													{menuName}
												</div>
												{/* <div className={styles.mobileSubMenu}>
													{submenu.map(({ subId, sub, subUrl }) => (
														<div
															className={styles.eachMenu}
															key={subId}
															onClick={() => history.push(subUrl)}>
															<div className={styles.text}>{sub}</div>
														</div>
													))}
												</div> */}
											</div>
											<Divider light />
										</div>
									))}

									<MenuItem onClick={handleClose}>
										<div className={styles.account} onClick={handleAccount}>
											<div>
												<img src={ProfileIcon} alt='Profile icon' />
											</div>
											<div className={styles.accountDetail}>Profile</div>
										</div>
									</MenuItem>

									<MenuItem onClick={handleClose}>
										<div onClick={signOutHandler} className={styles.account}>
											<div>
												<img src={SignOutIcon} alt='Sign out icon' />
											</div>
											<div className={styles.signOut}>Sign Out</div>
										</div>
									</MenuItem>
								</Menu>
							</div>
						</div>
					</div>
				</div>
			)}
		</div>
	);
};

export default NavBar;
