import React, { useState, useEffect } from 'react';
import { menu } from '../../utils/menu';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { changeNavbar } from '../../redux/actions/navbar/navbarActions';
import styles from './SideDrawer.module.scss';
import LogoSmall from '../../assets/images/Logos/paymentgateway.png';
import Times from '../../assets/images/Icons/CloseIcon.svg';
import signout from '../../assets/images/Icons/signout.svg';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

function SideDrawer({
	selectedMenu,
	selectedNav,
	setSelectedMenu,
	setSelectedNav,
	setCollapse,
	collapse,
}: {
	selectedMenu: number;
	selectedNav: number;
	setSelectedMenu: any;
	setSelectedNav: any;
	collapse: boolean;
	setCollapse: React.Dispatch<React.SetStateAction<boolean>>;
}) {
	interface clickType {
		id: null | number;
		isClick: Boolean;
	}
	const [isClick, setIsClick] = useState<clickType>({
		id: null,
		isClick: false,
	});
	const [isDesktop, setDesktop] = useState(window.innerWidth > 920);
	const location = useLocation();
	const path = location.pathname;
	const dispatch = useDispatch();
	const history = useHistory();
	const openMenu = ({ url }: any) => {
		history.push(url);
	};
	const signOutHandler = () => {
		sessionStorage.clear();
		setSelectedMenu(1);
		setSelectedNav(1);
		window.location.href = '/';
		localStorage.clear();
	};

	return (
		<div className={styles.sidewrapper}>
			<div className={styles.logowrapper}>
				<img className={styles.logo} src={LogoSmall} alt='' />
				{/* <span className={styles.logocontent}>Admin</span> */}
			</div>
			<div className={styles.flexwrapper}>
				<div>
					<div className={styles.menuwrapper}>
						{menu.map(({ id, menuName, url, icon, nested }) => (
							<>
								<div
									className={
										path === url
											? styles.menuActiveIndividual
											: styles.menuIndividual
									}>
									<div
										className={
											path === url && url
												? styles.iconActive
												: styles.icon
										}>
										{icon}
									</div>
									<div
										key={id}
										className={
											path === url && url
												? styles.activeMenu
												: styles.menuItem
										}
										onClick={() => {
											url && openMenu({ url });
											url && setSelectedMenu(id);
											url && setSelectedNav(id);
											url && dispatch(changeNavbar(menuName));
											!url &&
												setIsClick({
													id: id,
													isClick: !isClick.isClick,
												});
										}}>
										{path === url ? (
											<div className={styles.activeColor}>
												<div className={styles.activeColorText}>{menuName}</div>
											</div>
										) : (
											<div style={{ display: 'flex' }}>
												<div className={styles.inactiveColorText}>
													{menuName}
												</div>
												{!url && !isClick.isClick && isClick.id === id ? (
													<KeyboardArrowDownIcon sx={{ marginLeft: '5px' }} />
												) : !url ? (
													<KeyboardArrowUpIcon sx={{ marginLeft: '5px' }} />
												) : (
													''
												)}
												{/* {!url && isClick.isClick && (
													
												)} */}
											</div>
										)}
									</div>
								</div>
								<div>
									{nested &&
										isClick.isClick &&
										isClick.id === id &&
										nested.map(({ id, menuName, url }) => (
											<div
												className={
													path === url
														? styles.menuActiveIndividual
														: styles.menuIndividual
												}>
												<div
													key={id}
													className={
														path === url
															? styles.activeMenuNested
															: styles.menuItemNested
													}
													onClick={() => {
														openMenu({ url });
														setSelectedMenu(id);
														setSelectedNav(id);
														dispatch(changeNavbar(menuName));
													}}>
													{path === url ? (
														<div className={styles.activeColor}>
															<div className={styles.activeColorText}>
																{menuName}
															</div>
														</div>
													) : (
														<div className={styles.inactiveColorText}>
															{menuName}
														</div>
													)}
												</div>
											</div>
										))}
								</div>
							</>
						))}
					</div>
					{ }
					<div onClick={() => setCollapse(!collapse)} className={styles.close}>
						<img className={styles.closeIcon} src={Times} alt='' />
						<span className={styles.closeContent}>Minimize</span>
					</div>

					<div onClick={signOutHandler} className={styles.closeSign}>
						<img className={styles.closeIcon} src={signout} alt='' />
						<span
							style={{ paddingLeft: collapse ? '30px' : '' }}
							className={styles.closeContent}>
							Sign Out
						</span>
					</div>
				</div>
			</div>
		</div>
	);
}

export default SideDrawer;
