import { useState } from 'react';
import styles from './../Chargeback.module.scss';
import { ReactComponent as CloseIcon } from '../../../assets/images/Icons/closenew.svg';
import { Divider } from 'antd';
import { openToastAndSetContent } from '../../../redux/actions/toast/toastActions';
import client from '../../../api/client';
import {
	ToastErrorStyles,
	ToastSuccessStyles,
} from '../../../constants/toastStyles';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import MultipleUpload from '../../../components/multipleUpload/MultipleUpload';
import Modal from 'react-modal';
import { customStyles } from '../../../constants/modalStyles';
import FailedChargeback from './FailedChargeback';
import { closeLoader, openLoader } from '../../../redux/actions/loader/loaderActions';

interface dataComingTypes {
	currency: string;
	reference: string;
	amount: number;
	frequency: number;
	reason: string;
	due_date: string;
	type: string;
}

function Bulk({ closeModal, setBearer }: any) {
	const [file, setFile] = useState<dataComingTypes[]>([]);
	const dispatch = useDispatch();
	const history = useHistory();
	const [modalState, setIsOpenExtend] = useState<any>({
		isOpened: false,
		data: null
	});


	function closeModalExtend() {
		setIsOpenExtend({ isOpened: false, data: null });
	}
	const uploadMultipleFile = async () => {
		if (file && file?.length < 1) return window.alert('please, add a chargeback');
		try {
			dispatch(openLoader())
			const res: any = await client.post('/chargeback/upload', file);
			const message = res?.data?.message;
			const data: any[] = res?.data?.data;
			console.log({ data })
			const failedrecords = data.filter(x => {
				console.log({ x })
				return x.status?.toLowerCase() == "failed"
			});
			console.log({ failedrecords })
			if (failedrecords.length > 0) return setIsOpenExtend({ isOpened: true, data: failedrecords })
			closeModal();
			setBearer(true);
			dispatch(
				openToastAndSetContent({
					toastStyles: ToastSuccessStyles,
					toastContent: message,
				})
			);
		} catch (error: any) {
			console.log({ error })
			const message = error?.response?.data?.message;
			dispatch(
				openToastAndSetContent({
					toastStyles: ToastErrorStyles,
					toastContent: message,
				})
			);
		}
		finally {
			dispatch(closeLoader())
		}
	};


	return (
		<div className={styles.modalwrapper}>
			<div className={styles.modalhead}>
				<h3 className={styles.modalheadh3}>Log bulk chargeback</h3>
				<CloseIcon onClick={closeModal} style={{ cursor: 'pointer' }} />
			</div>
			<Divider style={{ margin: 0, padding: 0 }} />
			<div className={styles.modalcontent}>
				<MultipleUpload
					onDrop={(result: any) => {
						setFile(result);
					}}
				/>
				<a
					target='_blank'
					href='https://docs.google.com/spreadsheets/d/1V_sn6FDYXlADUeOCrk15sZG1hFBmFDHxScgifWgM35E/edit?usp=sharing'
					download
					className={styles.modaluploadp}>
					Download sample CSV file for bulk chargeback
				</a>
				<Divider style={{ margin: 0, padding: 0 }} />

				<div className={styles.modalFooter}>
					<button className={styles.cancel} onClick={closeModal}>
						Cancel
					</button>

					<button onClick={uploadMultipleFile} className={styles.fund}>
						Continue
					</button>
				</div>
			</div>
			{/* //MODAL FOR EXTEND */}
			<Modal
				isOpen={modalState.isOpened}
				onRequestClose={closeModalExtend}
				contentLabel='Example Modal'
				style={customStyles}>
				<FailedChargeback closeModal={closeModalExtend} data={modalState.data} />
			</Modal>
		</div>
	);
}

export default Bulk;
