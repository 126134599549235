import React from 'react';

export interface BusinessData {
	id: React.ReactNode;
	merchantId: React.ReactNode;
	businessName: React.ReactNode;
	emailAddress: string;
	countryName: string;
	name: string;
	businessType: string;
	contactPerson: string;
	date: string;
	status: string | React.ReactNode;
	action: React.ReactNode;
}

export const ColumnBusiness = [
	{
		Header: 'Merchant Id',
		accessor: 'id',
	},
	{
		Header: 'Core MID',
		accessor: 'merchantId',
	},
	{
		Header: 'Business name',
		accessor: 'businessName',
	},
	{
		Header: 'Business Type',
		accessor: 'businessType',
	},
	{
		Header: 'Email Address',
		accessor: 'emailAddress',
	},
	{
		Header: 'Country',
		accessor: 'countryName',
	},
	{
		Header: 'Contact person',
		accessor: 'contactPerson',
	},
	{
		Header: 'Sign Up date',
		accessor: 'date',
	},
	{
		Header: 'Status',
		accessor: 'status',
	},
	// {
	// 	Header: '',
	// 	accessor: 'action',
	// },
];
