import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Fade from '@mui/material/Fade';
import { Divider, Grid, Box } from '@mui/material';
import Switch from 'react-switch';
import styles from './Notificationbutton.module.scss';
import BoltIcon from '@mui/icons-material/Bolt';
import notificationsApi from '../../api/notifications';
import { notificationData } from '../../utils/notificationData';
import Modal from 'react-modal';
import { makeStyles } from '@material-ui/styles';
import { customStyles } from '../../constants/modalStyles';
import { useHistory } from 'react-router-dom';
import useApi from '../../hooks/useApi';
import KycIcon from '../../assets/images/Icons/kyc.svg';
import { ReactComponent as BellIcon } from '../../assets/images/Icons/bell.svg';
import client from '../../api/client';
import { openToastAndSetContent } from '../../redux/actions/toast/toastActions';
import {
	ToastErrorStyles,
	ToastSuccessStyles,
} from '../../constants/toastStyles';

interface user {
	email: string;
	firstname: string;
	lastname: string;
}

export default function NotificationButton() {
	const dispatch = useDispatch();
	const getNotificationsApi = useApi(notificationsApi.getNotifications);

	const fetchNotifications = async () => {
		getNotificationsApi.request();
	};

	const notificationsData = getNotificationsApi?.data?.data;

	console.log('notificationData', notificationsData);

	useEffect(() => {
		fetchNotifications();
	}, []);

	const [checked, setChecked] = useState<boolean>(false);

	const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
	const open = Boolean(anchorEl);

	const handleClick = (event: React.MouseEvent<HTMLElement>) => {
		setAnchorEl(event.currentTarget);
	};
	const handleClose = () => {
		setAnchorEl(null);
	};

	const history = useHistory();

	const useStyles = makeStyles({
		root: {
			'&:hover': {
				background: 'none',
			},
		},
		list: {
			backgroundColor: '#ffffff',
			overflow: 'hidden',
			color: 'rgba(0, 40, 65, 0.8)',
			display: 'flex',
			flexDirection: 'column',
			alignItems: 'flex-start',
			justifyContent: 'flex-start',
			padding: '0',
		},
		primary: {
			fontSize: '212px',
		},
		paper: {
			boxShadow: '0px 4px 11px rgba(0, 0, 0, 0.2)',
		},
	});
	const classes = useStyles();

	const handleTerms = () => {};

	const handleCheckedPush = async (id: number, push: boolean) => {
		const payload = { update_push: !push, notification_type_id: id };
		try {
			const data: any = await client.post('notification/user/update', payload);
			const message = data?.data?.message;
			fetchNotifications();
			dispatch(
				openToastAndSetContent({
					toastStyles: ToastSuccessStyles,
					toastContent: message,
				})
			);
		} catch (error: any) {
			const message = error?.response?.data?.message;
			dispatch(
				openToastAndSetContent({
					toastStyles: ToastErrorStyles,
					toastContent: message,
				})
			);
		}
	};

	const handleCheckedEmail = async (id: number, email: boolean) => {
		const payload = { update_email: !email, notification_type_id: id };
		try {
			const data: any = await client.post('notification/user/update', payload);
			const message = data?.data?.message;
			fetchNotifications();
			dispatch(
				openToastAndSetContent({
					toastStyles: ToastSuccessStyles,
					toastContent: message,
				})
			);
		} catch (error: any) {
			const message = error?.response?.data?.message;
			dispatch(
				openToastAndSetContent({
					toastStyles: ToastErrorStyles,
					toastContent: message,
				})
			);
		}
	};

	return (
		<div>
			<div
				id='fade-button'
				aria-controls='fade-menu'
				aria-haspopup='true'
				aria-expanded={open ? 'true' : undefined}
				onClick={handleClick}>
				<div onClick={handleClick}>
					<BellIcon />
				</div>
			</div>
			<Menu
				id='fade-menu'
				MenuListProps={{
					'aria-labelledby': 'fade-button',
				}}
				anchorEl={anchorEl}
				open={open}
				onClose={handleClose}
				TransitionComponent={Fade}
				classes={{
					list: classes.list,
					paper: classes.paper,
					root: classes.root,
				}}>
				<div className={styles.menuItem}>
					{notificationsData?.map((data: any, index: any) => (
						<Grid container spacing={0} key={data.id}>
							<Grid item md={8} xs={8} lg={8}>
								<div className={styles.header}>{data?.name}</div>
							</Grid>
							<Grid item md={4} xs={4} lg={4}>
								<div className={styles.switchDiv}>
									<div className={styles.controlButton}>
										<Switch
											checked={data?.push}
											onChange={() => {
												handleCheckedPush(data?.id, data?.push);
											}}
											offColor='#757575'
											height={20}
											width={30}
											uncheckedIcon={false}
											checkedIcon={false}
											onColor='#19943c'
										/>
										<span className={styles.ml1}>&nbsp;&nbsp;Push</span>
									</div>
									<div className={styles.controlButton}>
										<Switch
											checked={data?.email}
											onChange={() => {
												handleCheckedEmail(data?.id, data?.email);
											}}
											offColor='#757575'
											height={20}
											width={30}
											uncheckedIcon={false}
											checkedIcon={false}
											onColor='#19943c'
										/>
										<span style={{ marginTop: '-20px' }} className={styles.ml1}>
											&nbsp;&nbsp;Email
										</span>
									</div>
								</div>
							</Grid>
						</Grid>
					))}
				</div>
			</Menu>
		</div>
	);
}
