import React from 'react';

export interface BusinessModuleData {
	businessName: React.ReactNode;
	emailAddress: string;
	id: string;
	countryName: string;
	businessType: string;
	status: string;
	subsidiaries: string;
	action: React.ReactNode;
}

export const ColumnBusinessModule = [
	{
		Header: 'Business name',
		accessor: 'businessName',
	},
	{
		Header: 'Email Address',
		accessor: 'emailAddress',
	},
	{
		Header: 'Business Type',
		accessor: 'businessType',
	},
	{
		Header: 'Country',
		accessor: 'countryName',
	},
	{
		Header: 'Status',
		accessor: 'status',
	},
	{
		Header: 'Merchants',
		accessor: 'subsidiaries',
	},
	// {
	// 	Header: '',
	// 	accessor: 'action',
	// },
];
