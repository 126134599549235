import { ReactComponent as Home } from '../assets/images/Icons/HomeIcon2.svg';
import { ReactComponent as Account } from '../assets/images/Icons/AccountIcon.svg';
import { ReactComponent as Transaction } from '../assets/images/Icons/TransactionIcon.svg';
import { ReactComponent as Setting } from '../assets/images/Icons/SettingIcon.svg';
import { ReactComponent as Business } from '../assets/images/Icons/u_business.svg';
import { ReactComponent as Wallet } from '../assets/images/Icons/u_wallet.svg';
import { ReactComponent as Chargeback } from '../assets/images/Icons/u_chargeback.svg';
import { ReactComponent as Refunds } from '../assets/images/Icons/u_refund.svg';
import { ReactComponent as Fraud } from '../assets/images/Icons/u_fraud.svg';
import { ReactComponent as Fees } from '../assets/images/Icons/u_fees.svg';
import { ReactComponent as Settlement } from '../assets/images/Icons/u_home.svg';
import { ReactComponent as Channel } from '../assets/images/Icons/u_shopping-bag.svg';

export const menu = [
	{
		id: 1,
		menuName: 'Overview',
		url: '/dashboard',
		icon: <Home />,
	},
	{
		id: 2,
		menuName: 'Businesses',
		url: '/dashboard/businesses',
		icon: <Business />,
	},
	{
		id: 3,
		menuName: 'Channels',
		url: '/dashboard/channels',
		icon: <Channel />,
	},
	{
		id: 4,
		menuName: 'Transactions',
		url: '/dashboard/transactions',
		icon: <Transaction />,
	},
	{
		id: 5,
		menuName: 'Accounts',
		url: '/dashboard/accounts',
		icon: <Account />,
	},
	{
		id: 6,
		menuName: 'Settlements',
		// url: '/dashboard/settlements',
		icon: <Settlement />,
		nested: [
			{
				id: 7,
				menuName: 'Grouped Settlements',
				url: '/dashboard/settlements',
			},
			{
				id: 9,
				menuName: 'Pending Approval',
				url: '/dashboard/settlements/pending/approval',
			},
			{
				id: 60,
				menuName: 'Approved Settlements',
				url: '/dashboard/settlements/approved',
			},
			// {
			// 	id: 91,
			// 	menuName: 'Logged Settlement',
			// 	url: '/dashboard/settlements/logged',
			// },
			{
				id: 8,
				menuName: 'Reconciliations',
				url: '/dashboard/settlements/reconciliations',
			},
			{
				id: 10,
				menuName: 'Reports',
				url: '/dashboard/settlements/reports',
			},
			{
				id: 11,
				menuName: 'Settlement Plans',
				url: '/dashboard/settlements/settlement-plans',
			},
		],
	},
	{
		id: 11,
		menuName: 'Balances',
		url: '/dashboard/balances',
		icon: <Wallet />,
	},
	{
		id: 12,
		menuName: 'Chargebacks',
		url: '/dashboard/chargebacks',
		icon: <Chargeback />,
	},
	{
		id: 13,
		menuName: 'Refunds',
		url: '/dashboard/refunds',
		icon: <Refunds />,
	},
	// {
	// 	id: 14,
	// 	menuName: 'FRAUD & RISK',
	// 	// url: '/dashboard/fruad',
	// 	icon: <Fraud />,
	// 	nested: [
	// 		{
	// 			id: 15,
	// 			menuName: 'Sales',
	// 			url: '/dashboard/fruad',
	// 		},
	// 		{
	// 			id: 16,
	// 			menuName: 'Chargeback breakdown',
	// 			url: '/dashboard/fruad/chargeback_breakdown',
	// 		},
	// 		{
	// 			id: 17,
	// 			menuName: 'CB & Fraud summary',
	// 			url: '/dashboard/fruad/cb_fraudsummary',
	// 		},
	// 		{
	// 			id: 18,
	// 			menuName: 'CB & Fraud ratio',
	// 			url: '/dashboard/fruad/cb_fraudratio',
	// 		},
	// 		{
	// 			id: 19,
	// 			menuName: 'Assessment fee',
	// 			url: '/dashboard/fruad/assessment_fee',
	// 		},
	// 		{
	// 			id: 20,
	// 			menuName: 'Audit trails',
	// 			url: '/dashboard/fruad/audittrails',
	// 		},
	// 	],
	// },
	{
		id: 21,
		menuName: 'Fee & Limits',
		// url: '/dashboard/fees',
		icon: <Fees />,
		nested: [
			{
				id: 212,
				menuName: 'Fees',
				url: '/dashboard/fees',
			},
			{
				id: 213,
				menuName: 'Limits',
				url: '/dashboard/fees/limits',
			},
			// {
			// 	id: 214,
			// 	menuName: 'Providers',
			// 	url: '/dashboard/fees/providers',
			// },
			{
				id: 215,
				menuName: 'Approvals',
				url: '/dashboard/fees/approvals',
			},
			// {
			// 	id: 25,
			// 	menuName: 'Merchant settings',
			// 	url: '/dashboard/fees/merchant_settings',
			// },
			// {
			// 	id: 26,
			// 	menuName: 'Risk and fraud mgt',
			// 	url: '/dashboard/fees/Risk_fraudmgt',
			// },
		],
	},
	{
		id: 22,
		menuName: 'Groups',
		// url: '/dashboard/fees',
		icon: <Fees />,
		nested: [
			{
				id: 221,
				menuName: 'Pricing Groups',
				url: '/dashboard/groups/pricings',
			},
			{
				id: 222,
				menuName: 'Subsidiary Groups',
				url: '/dashboard/groups/subsidiaries',
			},
			{
				id: 223,
				menuName: 'Settlement Groups',
				url: '/dashboard/groups/settlements',
			},
			// {
			// 	id: 224,
			// 	menuName: 'Limit Groups',
			// 	url: '/dashboard/groups/limits',
			// },
			// {
			// 	id: 225,
			// 	menuName: 'Payment Option Groups',
			// 	url: '/dashboard/groups/payment-options',
			// },
		],
	},
	{
		id: 26,
		menuName: 'Settings',
		url: '/dashboard/settings',
		icon: <Setting />,
	},
];
