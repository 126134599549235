import React, { useState, Fragment, useEffect } from 'react';
import styles from './Channel.module.scss';
import PaginationTable from '../../components/table/pagination-table';
import { ColumnChannel, ChannelData } from '../../types/TablesTypes/Channels';
import MenuOption from '../../components/MenuOption/MenuOption';
import StatusView from '../../components/StatusView/StatusView';
import TableHeader from '../../components/TableHeader/TableHeader';
import ViewIcon from '../../assets/images/Icons/view-details.svg';
import DeactivateIcon from '../../assets/images/Icons/deactivate-user.svg';
import { ReactComponent as LinkIcon } from '../../assets/images/Icons/link-icon.svg';
import businessApi from '../../api/businesses';
import useApi from '../../hooks/useApi';
import client from '../../api/client';
import { useDispatch } from 'react-redux';
import { openToastAndSetContent } from '../../redux/actions/toast/toastActions';
import {
	ToastSuccessStyles,
	ToastErrorStyles,
} from '../../constants/toastStyles';
import Modal from 'react-modal';
import { customStyles } from '../../constants/modalStyles';
import CreateCustomer from '../../components/ModalContent/CreateCustomer/CreateCustomer';
import { useHistory } from 'react-router-dom';
import {
	closeLoader,
	openLoader,
} from '../../redux/actions/loader/loaderActions';
import moment from 'moment';
import FilterModal from '../../components/filterConfig/FilterModal';
import dayjs, { Dayjs } from 'dayjs';
import currencyApi from '../../api/currencies';
import {
	dateNow,
	sevenDaysAgo,
	thirtyDaysAgo,
	startOfYear,
	endOfYear,
} from '../../utils/datefunction';
import ChannelDetails from './ChannelDetails/ChannelDetails';
import AddChannel from './AddChannel/AddChannel';
import Curency from "../../api/currencies";

const tableStatus = [
	{ id: 1, name: 'New' },
	{ id: 2, name: 'Pending-Verification' },
];

interface detailProps {
	id: number;
	merchantid: string;
	channel: string;
	paymentmethod: string;
	rail: string;
	scheme: string;
	bank: string;
	currency: string;
	mid: string;
	username: string;
	password: string;
	url: string;
	createdat: string;
	status: string;
}

const Channel = () => {
	const history = useHistory();
	const dispatch = useDispatch();
	const [tableRow, setTableRow] = useState<any[]>();
	const [isFilterModalOpen, setIsFilterModalOpen] = useState(false);
	const [id, setId] = useState<number | null>(null);
	const [iden, setIden] = useState<string>('');

	const [businesses, setBusinesses] = useState<any>();
	const [businessDetail, setBusinessDetail] = useState<any>();
	const getBusinessApi = useApi(businessApi.getBusiness);
	const [modalIsOpen, setIsOpen] = React.useState(false);
	function closeModal() {
		setIsOpen(false);
		fetchBusinesses();
	}
	const getCurrenciesApi = useApi(currencyApi.getCurrencies);
	const getCountriesApi = useApi(currencyApi.getCountries);

	const fetchCurrencies = async () => {
		getCurrenciesApi.request();
		getCountriesApi.request();
	};

	const currencies = getCurrenciesApi?.data?.data;
	const countries = getCountriesApi?.data?.data;

	//PAGINATION
	const [pageNumber, setPageNumber] = React.useState<number>(1);
	const [rowsPerPage, setRowsPerPage] = React.useState<number>(10);
	const [nextPage, setNextPage] = useState<number | null>(null);
	const [previousPage, setPreviousPage] = useState<number | null>(null);

	//FILTERING
	const [value, setValue] = useState('');
	const [dropdown, setDropdown] = useState(false);
	const [eventDate, setEventDate] = useState('');
	const [fromDate, setFromDate] = useState<Dayjs | null | string>('');
	const [toDate, setToDate] = useState<Dayjs | null | string>('');
	const [country, setCountry] = useState('');
	const [status, setStatus] = useState('');
	const [bearer, setBearer] = useState(false);
	const [businessid, setBusinessid] = useState('');
	const [businesstype, setBusinesstype] = useState('');
	const [scheme, setScheme] = useState('');
	const [rail, setRail] = useState('');
	const [merchantName, setMerchantName] = useState('');
	const [channelmid, setChannelMid] = useState('');
	const [currencySelected, setSelectedCurrency] = useState('');
	const [bank, setSelectedBank] = useState('');

	const clearHandler = () => {
		setEventDate('');
		setFromDate('');
		setToDate('');
		setCountry('');
		setStatus('');
		setDropdown(false);
		setBearer(true);
		setBusinesstype('');
		setScheme('');
		setRail('');
		setMerchantName('');
		setSelectedCurrency('');
		setChannelMid('');
		setSelectedBank('');
	};

	useEffect(() => {
		if (eventDate === 'today') {
			setFromDate(dateNow);
			setToDate(dateNow);
		} else if (eventDate === 'last7days') {
			setFromDate(sevenDaysAgo);
			setToDate(dateNow);
		} else if (eventDate === 'last30days') {
			setFromDate(thirtyDaysAgo);
			setToDate(dateNow);
		} else if (eventDate === 'oneyear') {
			setFromDate(startOfYear);
			setToDate(endOfYear);
		} else {
			setFromDate('');
			setToDate('');
		}
	}, [eventDate]);

	const [allowedcurrencies, setAllowedCurrencies] = useState([
		{
			name: "Nigeria Naira",
			shortName: "NGN",
		},
	]);

	useEffect(() => {
		Curency.getCurrencies()
			.then((res: any) => {
				const currencies = res?.data?.data
				currencies.map((x: any) => x.value = x.shortName)
				console.log({ currencies })
				setAllowedCurrencies([...currencies])
			})
			.catch((err) => console.log(err));
	}, []);
	const filteredArray = [
		{
			name: 'Merchant Name',
			value: merchantName,
			setValue: setMerchantName,
		},
		{
			name: 'Channel Mid',
			value: channelmid,
			setValue: setChannelMid,
		},
		{
			name: 'Bank',
			value: bank,
			setValue: setSelectedBank,
		},
		{
			name: 'Payment Method',
			value: businesstype,
			setValue: setBusinesstype,
			selective: [
				{
					name: "Bank Transfer",
					value: "Bank-Transfer",
				},
				{
					name: "Card Payment",
					value: "Card",
				},
				{
					name: "Bank Account",
					value: "Bank-Account",
				},
				{
					name: "USSD",
					value: "USSD",
				},
				{
					name: "QR Code Payment",
					value: "NQR",
				},
			],
		},
		{
			name: 'Scheme',
			value: scheme,
			setValue: setScheme,
			selective: [
				{ name: "VISA", value: "VISA" },
				{ name: "MASTERCARD", value: "MASTERCARD" },
				{ name: "VERVE", value: "VERVE" },
			],
		},
		{
			name: 'Rails',
			value: rail,
			setValue: setRail,
			selective: [
				{
					name: "MPGS",
					value: "MPGS",
				},
				{
					name: "POLARIS",
					value: "POLARIS",
				},
				{
					name: "VISA",
					value: "VISA",
				},
				{
					name: "VERVE",
					value: "VERVE",
				},
			],
		},
		{
			name: 'Currencies',
			value: currencySelected,
			setValue: setSelectedCurrency,
			selective: [...allowedcurrencies],
		},
		{
			name: 'Status',
			value: status,
			setValue: setStatus,
			selective: [{ name: "ACTIVE", value: "ACTIVE" }, { name: "INACTIVE", value: "INACTIVE" }],
		},
	];
	useEffect(() => {
		fetchCurrencies();
	}, []);

	const fetchBusinesses = async (isexport = false) => {
		try {
			console.log({ status })
			let page = pageNumber;
			let size = rowsPerPage
			if (isexport) {
				page = 1;
				size = 100000;
			}
			dispatch(openLoader());
			const res: any = await client.get(
				`payment/rails/all?
				isActive=${!!status ? status == "ACTIVE" : ''}
				&id=${businessid}
				&MerchantId=${businessid}
				&paymentmethod=${businesstype}
				&fromdate=${fromDate}
				&todate=${toDate}
				&MerchantName=${merchantName}
				&currency=${currencySelected}
				&channelBank=${bank}
				&Rail=${rail}
				&Scheme=${scheme}
				&channelMid=${channelmid}
				&size=${size}
				&page=${page}`
			);
			const data = res?.data?.data ?? {}
			console.log({ xx: data })
			setBusinesses({ ...data });
			setBearer(false);
			dispatch(closeLoader());
			return data.items ?? [];
		} catch (error: any) {
			const { message } = error?.response?.data ?? { message: "" };
			dispatch(closeLoader());
			dispatch(
				openToastAndSetContent({
					toastContent: message,
					toastStyles: ToastErrorStyles,
				})
			);
		}
	};

	useEffect(() => {
		fetchBusinesses();
	}, [bearer, pageNumber, rowsPerPage, businessid]);

	useEffect(() => {
		setPageNumber(businesses?.page?.page || 1);
		setPreviousPage((businesses?.page?.page || 1) - 1);
		setNextPage((businesses?.page?.page || 1) + 1);
	}, [businesses]);

	const menuOptions = [
		{
			id: 1,
			name: (id: number) => {
				return 'View details';
			},
			icon: ViewIcon,
			onclick: (business: detailProps) => {
				setId(business.id);
				setBusinessDetail(business);
			},
		},
		{
			id: 2,
			name: (id: number) => {
				return 'Edit channel';
			},
			icon: ViewIcon,
			onclick: (business: detailProps) => {
				setBusinessDetail(business);
				setIsOpen(true);
				setIden('edit');
			},
		},
	];

	const BusiComp = ({ logo, name }: { logo: string; name: string }) => {
		return (
			<div className={styles.busiCompWrapper}>
				<div className={styles.busiCompWrapperImg}>
					<img src={logo} alt='' />
				</div>
				<p className={styles.busiCompWrapperp}>{name}</p>
			</div>
		);
	};

	const dataBusinesses = () => {
		const tempArr: ChannelData[] = [];
		businesses?.items?.reverse()?.forEach((business: any, index: number) => {
			return tempArr.push({
				id: business.id,
				merchantId: business?.coreMerchant?.merchantId,
				payment: business?.paymentmethod,
				currency: business?.currency,
				dateCreated: moment(business?.dateCreated).format('MMMM Do YYYY, h:mm a'),
				cardtype: business?.scheme,
				merchantName: business?.coreMerchant?.tradingName,
				channel: business?.bank,
				bank: business?.bank,
				rail: business?.rail,
				channelmid: business?.mid,
				status: (
					<StatusView
						status={business?.status ?? "ACTIVE"}
						orange='INACTIVE'
						green='ACTIVE'
					/>
				),
				action: (
					<MenuOption
						menuContent={
							<div>
								{menuOptions.map((option: any) => (
									<div
										key={option?.id}
										onClick={(e) => {
											option?.onclick(business, e);
										}}
										className={styles.menuContent}>
										<img
											src={option?.icon}
											alt={option?.name(business?.id)}
											width={24}
											height={24}
										/>
										<div className={styles.optionName}>
											{option?.name(business?.status)}
										</div>
									</div>
								))}
							</div>
						}
					/>
				),
			});
		});
		return tempArr;
	};

	useEffect(() => {
		setTableRow(dataBusinesses());
	}, [businesses?.items]);

	const itemLength = businesses?.profiles?.length;

	const addHandler = () => {
		setIden('add');
		setIsOpen(true);
	};
	return (
		<div style={{ marginTop: '38px', minHeight: '100vh' }}>
			{!id ? (
				<>
					<TableHeader
						pageName='Channels'
						data={businesses?.items}
						dataLength={businesses?.page?.total}
						value={businessid}
						setValue={setBusinessid}
						dropdown={dropdown}
						setDropdown={setDropdown}
						placeHolder='Search by Merchant Id'
						exporting={true}
						fetchDataHandler={() => fetchBusinesses(true)}
						newButton={
							<div onClick={addHandler} className={styles.createLink}>
								Add channel
							</div>
						}
						FilterComponent={
							<FilterModal
								eventDate={eventDate}
								setEventDate={setEventDate}
								dropdown={dropdown}
								setDropdown={setDropdown}
								setFromDate={setFromDate}
								setToDate={setToDate}
								fromDate={fromDate}
								toDate={toDate}
								setBearer={setBearer}
								clearHandler={clearHandler}
								filteredArray={filteredArray}
							/>
						}
					/>

					{getBusinessApi.loading ? (
						<div className={styles.loading} />
					) : (
						<PaginationTable
							data={tableRow ? tableRow : []}
							columns={ColumnChannel ? ColumnChannel : []}
							emptyPlaceHolder={
								businesses?.page?.total == 0
									? 'You currently do not have any data'
									: 'Loading...'
							}
							value={value}
							total={businesses?.page?.total ?? 0}
							totalPage={businesses?.page?.totalPage ?? 0}
							pageNumber={pageNumber}
							setPageNumber={setPageNumber}
							nextPage={nextPage}
							setNextPage={setNextPage}
							previousPage={previousPage}
							setPreviousPage={setPreviousPage}
							rowsPerPage={rowsPerPage}
							setRowsPerPage={setRowsPerPage}
						/>
					)}
					{/* //MODAL FOR ADD */}
					<Modal
						isOpen={modalIsOpen}
						onRequestClose={closeModal}
						contentLabel='Example Modal'
						style={customStyles}>
						<AddChannel
							closeModal={closeModal}
							identifier={iden}
							setIden={setIden}
							data={businessDetail}
						/>
					</Modal>
				</>
			) : (
				<ChannelDetails setId={setId} customerDetails={businessDetail} />
			)}
		</div>
	);
};

export default Channel;
