import React from 'react';

export interface TransactionsData {
	date: string;
	id: number;
	merchantName: string;
	merchantId: string;
	customerId: string;
	orderPaymentId: string;
	customerName: string;
	email: string;
	transactionAmount: string;
	paymentChannel: string;
	status: React.ReactNode;
	action: React.ReactNode;
}

export const ColumnTransactions = [
	{
		Header: 'Date',
		accessor: 'date',
	},
	{
		Header: 'Merchant Name',
		accessor: 'merchantName',
	},
	{
		Header: 'Customer ID',
		accessor: 'customerId',
	},
	{
		Header: 'Customer name',
		accessor: 'customerName',
	},
	{
		Header: 'Email Address',
		accessor: 'email',
	},
	{
		Header: 'Transaction Amount',
		accessor: 'transactionAmount',
	},
	{
		Header: 'Payment Channel',
		accessor: 'paymentChannel',
	},
	{
		Header: 'Status',
		accessor: 'status',
	},
	{
		Header: '',
		accessor: 'action',
	},
];

export interface TransactionsDataPerCustomer {
	date: string;
	merchantName: string;
	reference: string;
	transactionAmount: string;
	paymentChannel: string;
	status: React.ReactNode;
}

export const ColumnTransactionsPerCustomer = [
	{
		Header: 'Date',
		accessor: 'date',
	},
	{
		Header: 'Transaction Amount',
		accessor: 'transactionAmount',
	},
	{
		Header: 'Payment Channel',
		accessor: 'paymentChannel',
	},
	{
		Header: 'Reference',
		accessor: 'reference',
	},
	{
		Header: 'Merchant name',
		accessor: 'merchantName',
	},

	{
		Header: 'Status',
		accessor: 'status',
	},
];

export interface TransactionsDataPerBusiness {
	date: string;
	merchantName: string;
	reference: string;
	transactionAmount: string;
	paymentChannel: string;
	status: React.ReactNode;
}

export const ColumnTransactionsPerBusiness = [
	{
		Header: 'Date',
		accessor: 'date',
	},
	{
		Header: 'Customer name',
		accessor: 'merchantName',
	},
	{
		Header: 'Email Address',
		accessor: 'email',
	},

	{
		Header: 'Transaction Amount',
		accessor: 'transactionAmount',
	},
	{
		Header: 'Payment Channel',
		accessor: 'paymentChannel',
	},

	{
		Header: 'Status',
		accessor: 'status',
	},
];
