import React, { useState, useEffect } from 'react';
import styles from './SettlementPS.module.scss';
import PaginationTable from '../../../../components/table/pagination-table';
import {
	ColumnSettlementsReconciliation,
	SettlementsReconciliationData,
} from '../../../../types/TablesTypes/Reconciliation';
import TableHeader from '../../../../components/TableHeader/TableHeader';
import client from '../../../../api/client';
import { numberWithCommas } from '../../../../utils/formatNumber';
import {
	openLoader,
	closeLoader,
} from '../../../../redux/actions/loader/loaderActions';
import { useDispatch, useSelector } from 'react-redux';
import {
	ToastErrorStyles,
} from '../../../../constants/toastStyles';
import { openToastAndSetContent } from '../../../../redux/actions/toast/toastActions';
import FilterModal from '../../../../components/filterConfig/FilterModal';
import { Dayjs } from 'dayjs';
import {
	dateNow,
	sevenDaysAgo,
	thirtyDaysAgo,
	startOfYear,
	endOfYear,
} from '../../../../utils/datefunction';

const SettlementPS = () => {
	const dispatch = useDispatch();
	const [tableRow, setTableRow] = useState<any[]>();
	const [settlement, setSettlement] = useState<any>();

	//PAGINATION
	const [pageNumber, setPageNumber] = React.useState<number>(1);
	const [rowsPerPage, setRowsPerPage] = React.useState<number>(10);
	const [nextPage, setNextPage] = useState<number | null>(null);
	const [previousPage, setPreviousPage] = useState<number | null>(null);

	//FILTERING
	const [value, setValue] = useState('');
	const [dropdown, setDropdown] = useState(false);
	const [eventDate, setEventDate] = useState('');
	const [fromDate, setFromDate] = useState<Dayjs | null | string>('');
	const [toDate, setToDate] = useState<Dayjs | null | string>('');
	const [paymentreference, setPaymentReference] = useState('');
	const [orderreference, setOrderReference] = useState('');
	const [status, setStatus] = useState('');
	const [email, setEmail] = useState('');
	const [bearer, setBearer] = useState(false);

	const clearHandler = () => {
		setEventDate('');
		setFromDate('');
		setToDate('');
		setPaymentReference('');
		setOrderReference('');
		setStatus('');
		setDropdown(false);
		setBearer(true);
	};

	const { currency } = useSelector((state) => state.appSettingsReducer);

	useEffect(() => {
		if (eventDate === 'today') {
			setFromDate(dateNow);
			setToDate(dateNow);
		} else if (eventDate === 'last7days') {
			setFromDate(sevenDaysAgo);
			setToDate(dateNow);
		} else if (eventDate === 'last30days') {
			setFromDate(thirtyDaysAgo);
			setToDate(dateNow);
		} else if (eventDate === 'oneyear') {
			setFromDate(startOfYear);
			setToDate(endOfYear);
		} else {
			setFromDate('');
			setToDate('');
		}
	}, [eventDate]);

	const filteredArray = [
		{
			name: 'Payment Reference',
			value: paymentreference,
			setValue: setPaymentReference,
		},
		{
			name: 'Order Reference',
			value: orderreference,
			setValue: setOrderReference,
		},
		{
			name: 'Status',
			value: status,
			setValue: setStatus,
			selective: [
				{ name: 'Successful' },
				{ name: 'Initiated' },
				{ name: 'Failed' },
				{ name: 'Reversed' },
			],
		},
	];

	const fetchSettlement = async () => {
		status.replace(/-|\s/g, '');
		dispatch(openLoader());
		try {
			const { data }: any = await client.get(
				`settlement/all?currency=${currency}&subsidiaryname=${email}&settlementstatus=Completed&paymentreference=${paymentreference}&transactionreference=${orderreference}&fromdate=${fromDate}&todate=${toDate}&limit=${rowsPerPage}&page=${pageNumber}`
			);
			setSettlement(data?.data);
			dispatch(closeLoader());
			setBearer(false);
		} catch (error: any) {
			dispatch(closeLoader());
			const { message } = error?.response?.data;
			dispatch(
				openToastAndSetContent({
					toastContent: message,
					toastStyles: ToastErrorStyles,
				})
			);
		}
	};

	useEffect(() => {
		fetchSettlement();
	}, [bearer, pageNumber, rowsPerPage, email, currency]);

	useEffect(() => {
		setPageNumber(settlement?.page?.currentPage || 1);
		setPreviousPage(settlement?.page?.previousPage);
		setNextPage(settlement?.page?.nextPage);
	}, [settlement]);

	const dataSettlement = () => {
		const tempArr: SettlementsReconciliationData[] = [];
		settlement?.items
			?.slice(0)
			.reverse()
			.forEach((settlement: any, index: number) => {
				tempArr.push({
					transactionId: settlement.paymentReference,
					currency: settlement.currency,
					amount: `${settlement.currency} ${numberWithCommas(settlement?.transactionAmount)}`,
					reference: settlement.transactionReference,
					businessName: settlement.subsidiaryName,
					country: `${settlement.currency}`,
					date: settlement.dateCreated,
				});
			});
		return tempArr;
	};

	useEffect(() => {
		setTableRow(dataSettlement());
	}, [settlement?.items]);

	const itemLength = settlement?.length;

	return (
		<div className={styles.dashbordContainer}>
			<TableHeader
				pageName='Reconciliation'
				data={settlement?.items}
				dataLength={settlement?.page?.total}
				value={email}
				setValue={setEmail}
				dropdown={dropdown}
				setDropdown={setDropdown}
				searchfn={true}
				placeHolder='Search by name'
				FilterComponent={
					<>
						<FilterModal
							eventDate={eventDate}
							setEventDate={setEventDate}
							dropdown={dropdown}
							setDropdown={setDropdown}
							setFromDate={setFromDate}
							setToDate={setToDate}
							fromDate={fromDate}
							toDate={toDate}
							setBearer={setBearer}
							clearHandler={clearHandler}
							filteredArray={filteredArray}
						/>
					</>
				}
			/>

			<PaginationTable
				data={tableRow ? tableRow : []}
				columns={
					ColumnSettlementsReconciliation ? ColumnSettlementsReconciliation : []
				}
				value={value}
				emptyPlaceHolder={
					settlement?.page?.total == 0
						? 'You currently do not have any data'
						: 'Loading...'
				}
				total={settlement?.page?.total}
				totalPage={settlement?.page?.totalPage}
				pageNumber={pageNumber}
				setPageNumber={setPageNumber}
				nextPage={nextPage}
				setNextPage={setNextPage}
				previousPage={previousPage}
				setPreviousPage={setPreviousPage}
				rowsPerPage={rowsPerPage}
				setRowsPerPage={setRowsPerPage}
			/>
		</div>
	);
};

export default SettlementPS;
