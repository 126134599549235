import React from 'react';
import styles from './Declined.module.scss';
import Mark from '../../../assets/images/Icons/u_multiply (3).svg';
import { Divider } from 'antd';
import OutlineTextInput from '../../../components/input/OutlineTextInput';
import { ReactComponent as CloseIcon } from '../../../assets/images/Icons/closenew.svg';
import { useDispatch } from 'react-redux';
import client from '../../../api/client';
import {
	closeLoader,
	openLoader,
} from '../../../redux/actions/loader/loaderActions';
import { openToastAndSetContent } from '../../../redux/actions/toast/toastActions';
import {
	ToastErrorStyles,
	ToastSuccessStyles,
} from '../../../constants/toastStyles';

function Declined({
	closeModal,
	details,
	reason,
	identifier,
	fn,
}: {
	closeModal: any;
	details?: any;
	reason?: string;
	identifier?: string;
	fn: any;
}) {
	const dispatch = useDispatch();
	const acceptHandler = () => {
		dispatch(openLoader());

		if (identifier === 'provider') {
			client
				.post(`pricing/approve/pending/provider`, {
					id: details?.id,
					approved: false,
					reason: reason,
				})
				.then((res: any) => {
					dispatch(closeLoader());
					dispatch(
						openToastAndSetContent({
							toastContent: res.data.message,
							toastStyles: ToastSuccessStyles,
						})
					);
					closeModal();
				})
				.catch((error: any) => {
					dispatch(closeLoader());
					const { message } = error?.response?.data;
					dispatch(
						openToastAndSetContent({
							toastContent: message,
							toastStyles: ToastErrorStyles,
						})
					);
				});
		} else if (identifier === 'limit') {
			client
				.post(`limits/approve`, {
					id: details?.id,
					approved: false,
					type: details?.type,
					reason: reason,
				})
				.then((res: any) => {
					dispatch(closeLoader());
					dispatch(
						openToastAndSetContent({
							toastContent: res.data.message,
							toastStyles: ToastSuccessStyles,
						})
					);
					closeModal();
				})
				.catch((error: any) => {
					dispatch(closeLoader());
					const { message } = error?.response?.data;
					dispatch(
						openToastAndSetContent({
							toastContent: message,
							toastStyles: ToastErrorStyles,
						})
					);
				});
		} else if (identifier === 'fee') {
			client
				.post(`pricing/collection/approve`, {
					id: details?.id,
					approved: false,
					type: details?.type,
					reason: reason,
				})
				.then((res: any) => {
					dispatch(closeLoader());
					dispatch(
						openToastAndSetContent({
							toastContent: res.data.message,
							toastStyles: ToastSuccessStyles,
						})
					);
					closeModal();
				})
				.catch((error: any) => {
					dispatch(closeLoader());
					const { message } = error?.response?.data;
					dispatch(
						openToastAndSetContent({
							toastContent: message,
							toastStyles: ToastErrorStyles,
						})
					);
				});
		} else {
			return;
		}
	};
	return (
		<div className={styles.modalwrapper}>
			<div className={styles.modalhead}>
				<h3 className={styles.modalheadh3}>Decline provider</h3>
				<CloseIcon onClick={closeModal} style={{ cursor: 'pointer' }} />
			</div>
			<Divider style={{ margin: 0, padding: 0 }} />

			<div
				style={{
					padding: '32px 24px',
				}}>
				<div>
					<p>You are about to decline a provider on Payinvert.</p>

					<p>Do you want to proceed?</p>
				</div>
				<div style={{ marginTop: '35px' }}></div>
				<button className={styles.Downloadbutton}>
					<span className={styles.Downloadbutton_span}>
						<img src={Mark} alt='' />
					</span>
					Decline
				</button>
			</div>
		</div>
	);
}

export default Declined;
