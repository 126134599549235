import React, { useState, useEffect } from 'react';
import styles from './Settlements.module.scss';
import { useParams, useHistory } from 'react-router';
import axios from 'axios';
import client from '../../../api/client';
import {
	ToastSuccessStyles,
	ToastErrorStyles,
} from '../../../constants/toastStyles';
import { useDispatch } from 'react-redux';
import { openToastAndSetContent } from '../../../redux/actions/toast/toastActions';
import {
	closeLoader,
	openLoader,
} from '../../../redux/actions/loader/loaderActions';
import moment from 'moment';
import { Divider } from '@mui/material';
import Grid from '@mui/material/Grid';
import Modal from 'react-modal';
import { customStyles } from '../../../constants/modalStyles';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import StatusView from '../../../components/StatusView/StatusView';
import { ReactComponent as File } from '../../../assets/images/Icons/u_fileu.svg';
import { ReactComponent as Exclaim } from '../../../assets/images/Icons/u_exclaim.svg';
import { ReactComponent as Danger } from '../../../assets/images/Icons/danger.svg';
import { ReactComponent as Checky } from '../../../assets/images/Icons/checky.svg';

import Checkbox from '@mui/material/Checkbox';
import SettlementTransaction from './SettlementTransaction';
import OutlineTextInput from '../../../components/input/OutlineTextInput';

const SettlementDetails = () => {
	const [customerDetails, setCustomerDetails] = useState<any>([]);
	const [inputFlag, setInputFlag] = React.useState('');
	const [inputReview, setInputReview] = React.useState('');
	const { id } = useParams<{ id: any }>();
	const [isLoading, setIsLoading] = useState(false);
	const [modalIsOpenFlag, setIsOpenFlag] = React.useState(false);
	const [modalIsOpenReview, setIsOpenReview] = React.useState(false);
	const dispatch = useDispatch();
	const history = useHistory();

	function closeModalFlag() {
		setIsOpenFlag(false);
	}
	function closeModalReview() {
		setIsOpenReview(false);
	}

	// useEffect(() => {
	// 	dispatch(openLoader());
	// 	setIsLoading(true);
	// 	client
	// 		.get(`subsidiary/dashboard/fetch/customers/orders?customers?id=${id}`)
	// 		.then((res: any) => {
	// 			setCustomerDetails(res?.data?.items[0]);
	// 			setIsLoading(false);
	// 			dispatch(closeLoader());
	// 		})
	// 		.catch((error) => {
	// 			dispatch(closeLoader());
	// 			setIsLoading(false);
	// 			if (error.response) {
	// 				const { message } = error?.response?.data;
	// 				dispatch(
	// 					openToastAndSetContent({
	// 						toastStyles: ToastErrorStyles,
	// 						toastContent: message,
	// 					})
	// 				);
	// 			} else if (error?.response?.data?.message === 'Please login again') {
	// 				sessionStorage.clear();
	// 				window.location.href = '/';
	// 			} else if (error.request) {
	// 				console.log(error.request);
	// 			} else {
	// 				console.log('Error', error.message);
	// 			}
	// 		});
	// }, []);

	return (
		<div>
			{isLoading ? (
				<div />
			) : (
				<>
					<div className={styles.transactionsHeaderflex}>
						<div
							style={{
								color: '#211F01',
								display: 'inline',
								cursor: 'pointer',
								fontWeight: '800px',
							}}
							onClick={() => history.goBack()}>
							<ArrowBackIosIcon sx={{ fontSize: '20px' }} /> Back to due
							settlements
						</div>
						<div>
							<button
								onClick={() => setIsOpenReview(true)}
								className={styles.transactionsHeader_button_mark}>
								Mark as reviewed
							</button>
							<button
								onClick={() => setIsOpenFlag(true)}
								className={styles.transactionsHeader_button}>
								Flag as fraudulent
							</button>
						</div>
					</div>

					<div className={styles.detailBox}>
						<div className={styles.detailBoxCorner}>
							<div className={styles.transactionsHeaderleft}>
								<h3>NGN 45,000.00</h3>
								<StatusView
									status='successful'
									red='New'
									orange='Pending-Verification'
									green='successful'
								/>
							</div>
						</div>

						<Divider style={{ color: '#CECECD' }} />
						<div
							style={{
								padding: '0 24px',
							}}>
							<Grid container spacing={2}>
								<Grid item xs={12} sm={6} md={2} lg={2}>
									<div className={styles.customerInfo}>
										<div className={styles.detailsValue}>Transaction date</div>
										<div className={styles.detailsKey}>
											19 Sep, 2022 04:36pm
										</div>
									</div>
								</Grid>
								<Grid item xs={12} sm={6} md={4} lg={2}>
									<div className={styles.customerInfo}>
										<div className={styles.detailsValue}>Settlement date</div>
										<div className={styles.detailsKey}>
											19 Sep, 2022 04:36pm
										</div>
									</div>
								</Grid>
								<Grid item xs={12} sm={6} md={2} lg={2}>
									<div className={styles.customerInfo}>
										<div className={styles.detailsValue}>Merchant ID</div>
										<div className={styles.detailsKey}>SW-ab84jk03</div>
									</div>
								</Grid>
								<Grid item xs={12} sm={6} md={4} lg={3}>
									<div className={styles.customerInfo}>
										<div className={styles.detailsValue}>Merchant name</div>
										<div className={styles.detailsKey}>The Peters</div>
									</div>
								</Grid>
								<Grid item xs={12} sm={6} md={2} lg={2}>
									<div className={styles.customerInfo}>
										<div className={styles.detailsValue}>
											Total transaction amount
										</div>
										<div className={styles.detailsKey}>NGN 800,000</div>
									</div>
								</Grid>{' '}
								<Grid item xs={12} sm={6} md={2} lg={3}>
									<div className={styles.customerInfo}>
										<div className={styles.detailsValue}>Total fee</div>
										<div className={styles.detailsKey}>NGN 800,000</div>
									</div>
								</Grid>{' '}
								<Grid item xs={12} sm={6} md={2} lg={3}>
									<div className={styles.customerInfo}>
										<div className={styles.detailsValue}>Settlement type</div>
										<div className={styles.detailsKey}>Bank account</div>
									</div>
								</Grid>{' '}
								<Grid item xs={12} sm={6} md={2} lg={3}>
									<div className={styles.customerInfo}>
										<div className={styles.detailsValue}>Settlement bank</div>
										<div className={styles.detailsKey}>First bank</div>
									</div>
								</Grid>{' '}
								<Grid item xs={12} sm={6} md={2} lg={3}>
									<div className={styles.customerInfo}>
										<div className={styles.detailsValue}>Bank code</div>
										<div className={styles.detailsKey}>0928737879</div>
									</div>
								</Grid>{' '}
								<Grid item xs={12} sm={6} md={2} lg={2}>
									<div className={styles.customerInfo}>
										<div className={styles.detailsValue}>Account number</div>
										<div className={styles.detailsKey}>1234567890</div>
									</div>
								</Grid>{' '}
							</Grid>
						</div>
					</div>

					<div>
						<h5 className={styles.transH4}>4 transactions</h5>
						<SettlementTransaction />
					</div>

					{/* //MODAL FOR Flag */}
					<Modal
						isOpen={modalIsOpenFlag}
						onRequestClose={closeModalFlag}
						contentLabel='Example Modal'
						style={customStyles}>
						<div className={styles.modalwrapper}>
							<div className={styles.modalhead}>
								<h3 className={styles.modalheadh3}>Flag settlement</h3>
								<CloseIcon
									onClick={closeModalFlag}
									style={{ cursor: 'pointer' }}
								/>
							</div>
							<Divider style={{ margin: 0, padding: 0 }} />
							<div className={styles.modalcontent}>
								<h5 className={styles.flagh5}>
									Why are you flagging this settlement?
								</h5>
								<div className={styles.input}>
									<OutlineTextInput
										handleChange={(e) => setInputFlag(e.target.value)}
										inputName='comment'
										inputLabel='comment'
										inputValue={inputFlag}
										multiline
										style={{ width: '100%' }}
									/>
								</div>

								<div className={styles.modalFooterFlag}>
									<button
										style={{ background: '#ea5851' }}
										className={styles.flag_button}>
										<Danger />
										Flag settlement
									</button>
								</div>
							</div>
						</div>
					</Modal>
					{/* //MODAL FOR Review */}
					<Modal
						isOpen={modalIsOpenReview}
						onRequestClose={closeModalReview}
						contentLabel='Example Modal'
						style={customStyles}>
						<div className={styles.modalwrapper}>
							<div className={styles.modalhead}>
								<h3 className={styles.modalheadh3}>Review settlement</h3>
								<CloseIcon
									onClick={closeModalReview}
									style={{ cursor: 'pointer' }}
								/>
							</div>
							<Divider style={{ margin: 0, padding: 0 }} />
							<div className={styles.modalcontent}>
								<h5 className={styles.flagh5}>
									Are you sure you want to mark this settlement as reviewed and
									good for approval?
								</h5>
								<div className={styles.input}>
									<OutlineTextInput
										handleChange={(e) => setInputReview(e.target.value)}
										inputName='comment'
										inputLabel='comment'
										inputValue={inputReview}
										multiline
										style={{ width: '100%' }}
									/>
								</div>

								<div className={styles.modalFooterFlag}>
									<button
										style={{ background: '#19943c' }}
										className={styles.flag_button}>
										<Checky />
										Mark settlement as reviewed
									</button>
								</div>
							</div>
						</div>
					</Modal>
				</>
			)}
		</div>
	);
};

export default SettlementDetails;
