import { useState } from 'react';
import styles from './../Chargeback.module.scss';
import { ReactComponent as CloseIcon } from '../../../assets/images/Icons/closenew.svg';
import { Divider } from 'antd';
import { openToastAndSetContent } from '../../../redux/actions/toast/toastActions';
import client from '../../../api/client';
import {
	ToastErrorStyles,
	ToastSuccessStyles,
} from '../../../constants/toastStyles';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import MultipleUpload from '../../../components/multipleUpload/MultipleUpload';

interface dataComingTypes {
	currency: string;
	reference: string;
	amount: number;
	frequency: number;
	reason: string;
	due_date: string;
	type: string;
}

function FailedChargeback({ closeModal, setBearer, data }: any) {
	const [file, setFile] = useState<dataComingTypes[]>([]);
	const dispatch = useDispatch();
	const history = useHistory();

	return (
		<div className={styles.modalwrapper}>
			<div className={styles.modalhead}>
				<h3 className={styles.modalheadh3}>Chargeback Upload Feedback</h3>
				<CloseIcon onClick={() => closeModal({ isOPened: false, data: null })} style={{ cursor: 'pointer' }} />
			</div>
			<Divider style={{ margin: 0, padding: 0 }} />
			<div className={styles.modalcontent}>
				<div className={styles.failedChargebackWrapper}>
					{data?.map((x: any, i: number) => (
						<div key={x.reference + i} className={styles.failedChargeback}>
							<p><span>{i + 1}</span>. Reference: {x.reference}</p>
							<p>Status: {x.status}</p>
							<p>Message: {x.message}</p>
						</div>
					))}
				</div>
				<Divider style={{ margin: 0, padding: 0 }} />

				<div className={styles.modalFooter}>
					<button className={styles.cancel} onClick={() => closeModal({ isOPened: false, data: null })}>
						Close
					</button>
				</div>
			</div>
		</div>
	);
}

export default FailedChargeback;
