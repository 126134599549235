import React, { useState, useEffect } from "react";
import styles from "./Businesses.module.scss";
import PaginationTable from "../../components/table/pagination-table";
import { ColumnBusiness, BusinessData } from "../../types/TablesTypes/Business";
import MenuOption from "../../components/MenuOption/MenuOption";
import StatusView from "../../components/StatusView/StatusView";
import TableHeader from "../../components/TableHeader/TableHeader";
import ViewIcon from "../../assets/images/Icons/view-details.svg";
import businessApi from "../../api/businesses";
import useApi from "../../hooks/useApi";
import client from "../../api/client";
import { useDispatch } from "react-redux";
import { openToastAndSetContent } from "../../redux/actions/toast/toastActions";
import {
  ToastErrorStyles,
} from "../../constants/toastStyles";
import { useHistory } from "react-router-dom";
import {
  closeLoader,
  openLoader,
} from "../../redux/actions/loader/loaderActions";
import moment from "moment";
import FilterModal from "../../components/filterConfig/FilterModal";
import { Dayjs } from "dayjs";
import currencyApi from "../../api/currencies";
import {
  dateNow,
  sevenDaysAgo,
  thirtyDaysAgo,
  startOfYear,
  endOfYear,
} from "../../utils/datefunction";

const tableStatus = [
  { id: 1, name: "New" },
  { id: 2, name: "Pending-Verification" },
];

const Business = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [tableRow, setTableRow] = useState<any[]>();
  const [isFilterModalOpen, setIsFilterModalOpen] = useState(false);

  const [businesses, setBusinesses] = useState<any>();
  // const getBusinessApi = useApi(businessApi.getBusiness);
  const [modalIsOpen, setIsOpen] = React.useState(false);
  function closeModal() {
    setIsOpen(false);
  }
  const getCurrenciesApi = useApi(currencyApi.getCurrencies);
  const getCountriesApi = useApi(currencyApi.getCountries);

  const fetchCurrencies = async () => {
    getCurrenciesApi.request();
    getCountriesApi.request();
  };

  const currencies = getCurrenciesApi?.data?.data;
  const countries = getCountriesApi?.data?.data;

  //PAGINATION
  const [pageNumber, setPageNumber] = React.useState<number>(1);
  const [rowsPerPage, setRowsPerPage] = React.useState<number>(10);
  const [nextPage, setNextPage] = useState<number | null>(null);
  const [previousPage, setPreviousPage] = useState<number | null>(null);

  //FILTERING
  const [value, setValue] = useState("");
  const [dropdown, setDropdown] = useState(false);
  const [eventDate, setEventDate] = useState("");
  const [fromDate, setFromDate] = useState<Dayjs | null | string>("");
  const [toDate, setToDate] = useState<Dayjs | null | string>("");
  const [country, setCountry] = useState("");
  const [status, setStatus] = useState("");
  const [bearer, setBearer] = useState(false);
  const [businessname, setBusinessname] = useState("");
  const [businesstype, setBusinesstype] = useState("");
  const [merchantId, setMrechantId] = useState("");

  const clearHandler = () => {
    setEventDate("");
    setFromDate("");
    setToDate("");
    setCountry("");
    setStatus("");
    setDropdown(false);
    setBearer(true);
    setBusinesstype("");
  };

  useEffect(() => {
    if (eventDate === "today") {
      setFromDate(dateNow);
      setToDate(dateNow);
      console.log("datenow:", dateNow);
    } else if (eventDate === "last7days") {
      setFromDate(sevenDaysAgo);
      setToDate(dateNow);
    } else if (eventDate === "last30days") {
      setFromDate(thirtyDaysAgo);
      setToDate(dateNow);
    } else if (eventDate === "oneyear") {
      setFromDate(startOfYear);
      setToDate(endOfYear);
    } else {
      setFromDate("");
      setToDate("");
    }
  }, [eventDate]);

  const filteredArray = [
    {
      name: "Country",
      value: country,
      setValue: setCountry,
      selective: countries?.reduce(
        (
          memo: any,
          { shortName, name }: { shortName: string; name: string }
        ) => (memo = [...memo, { name: name, value: shortName }]),
        []
      ),
      selectHelper: false,
    },
    {
      name: "Business Type",
      value: businesstype,
      setValue: setBusinesstype,
      selective: [{ name: "Individual" }, { name: "Company" }],
    },
    {
      name: "Merchant Id",
      value: merchantId,
      setValue: setMrechantId,
    },
    {
      name: "Status",
      value: status,
      setValue: setStatus,
      selective: [
        { name: "New" },
        { name: "Pending-Verification" },
        { name: "In-Review" },
        { name: "Declined" },
        { name: "Active" },
        { name: "Dormant" },
        { name: "Closed" },
        { name: "Pending-Approval" },
      ],
    },
  ];

  useEffect(() => {
    fetchCurrencies();
  }, []);

  const fetchBusinesses = async (isDownload = false) => {
    dispatch(openLoader());
    try {
      const { data }: any = await client.get(
        `subsidiary/all/paginated?id=${merchantId}&status=${status}&businesstype=${businesstype}&country=${country}&fromdate=${fromDate}&todate=${toDate}&businessname=${businessname}&limit=${rowsPerPage}&page=${pageNumber}&isDownload=${isDownload}`
      );
      setBusinesses(data.data);
      dispatch(closeLoader());
      setBearer(false);
      return data?.data?.items || [];
    } catch (error: any) {
      dispatch(closeLoader());
      const { message } = error.response.data;
      dispatch(
        openToastAndSetContent({
          toastContent: message,
          toastStyles: ToastErrorStyles,
        })
      );
    }
  };

  useEffect(() => {
    fetchBusinesses();
  }, [bearer, businessname, pageNumber, rowsPerPage]);

  useEffect(() => {
    setPageNumber(businesses?.page || 1);
    setPreviousPage(businesses?.page - 1);
    setNextPage(businesses?.page + 1);
  }, [businesses]);

  const menuOptions = [
    {
      id: 1,
      name: (id: number) => {
        return "View business details";
      },
      icon: ViewIcon,
      onclick: (
        emailAddress: string,
        e: any,
        status: string,
        businessId: number
      ) => {
        history.push(`/dashboard/business/${businessId}`);
      },
    },
  ];

  const BusiComp = ({ logo, name }: { logo: string; name: string }) => {
    return (
      <div className={styles.busiCompWrapper}>
        <div className={styles.busiCompWrapperImg}>
          <img src={logo} alt="" />
        </div>
        <p className={styles.busiCompWrapperp}>{name}</p>
      </div>
    );
  };

  const dataBusinesses = () => {
    const tempArr: BusinessData[] = [];
    businesses?.items.reverse()
      .forEach((business: any, index: number) => {
        return tempArr.push({
          businessName: (
            <BusiComp logo={business.logo} name={business.businessName} />
          ),
          id: business?.id,
          name: business?.businessName,
          merchantId: business.merchantId ? business.merchantId : "N/A",
          emailAddress: business?.email,
          countryName: business?.country,
          businessType: business?.businessType,
          contactPerson: business?.contactPerson,
          date: moment(business?.dateCreated).format('MMMM Do YYYY, h:mm a'),
          // date: moment(business?.dateCreated).format("DD/M/YYYY"),
          status: (
            <StatusView
              status={business?.status}
              blue="Pending-Verification"
              orange="Pending-Approval"
              green="Active"
              red="New"
            />
          ),
          action: (
            <MenuOption
              menuContent={
                <div>
                  {menuOptions.map((option: any) => (
                    <div
                      key={option?.id}
                      onClick={(e) => {
                        option?.onclick(
                          business?.emailAddress,
                          e,
                          business?.status,
                          business?.id
                        );
                      }}
                      className={styles.menuContent}
                    >
                      <img
                        src={option?.icon}
                        alt={option?.name(business?.id)}
                        width={24}
                        height={24}
                      />
                      <div className={styles.optionName}>
                        {option?.name(business?.status)}
                      </div>
                    </div>
                  ))}
                </div>
              }
            />
          ),
        });
      });
    return tempArr;
  };

  useEffect(() => {
    setTableRow(dataBusinesses());
  }, [businesses?.items]);

  return (
    <div style={{ marginTop: "38px", minHeight: "100vh" }}>
      <TableHeader
        pageName="Businesses"
        data={businesses?.items}
        dataLength={businesses?.total}
        value={businessname}
        setValue={setBusinessname}
        dropdown={dropdown}
        setDropdown={setDropdown}
        placeHolder="Search business name"
        fetchDataHandler={() => fetchBusinesses(true)}
        FilterComponent={
          <FilterModal
            eventDate={eventDate}
            setEventDate={setEventDate}
            dropdown={dropdown}
            setDropdown={setDropdown}
            setFromDate={setFromDate}
            setToDate={setToDate}
            fromDate={fromDate}
            toDate={toDate}
            setBearer={setBearer}
            clearHandler={clearHandler}
            filteredArray={filteredArray}
          />
        }
      />
      <PaginationTable
        data={tableRow ? tableRow : []}
        columns={ColumnBusiness ? ColumnBusiness : []}
        emptyPlaceHolder={
          businesses?.total == 0 ? "No business data" : "Loading..."
        }
        value={value}
        total={businesses?.total}
        totalPage={businesses?.totalPage}
        pageNumber={pageNumber}
        setPageNumber={setPageNumber}
        nextPage={nextPage}
        clickAction={(bus => history.push(`/dashboard/business/${bus.id}?name=${bus.name}`))}
        setNextPage={setNextPage}
        previousPage={previousPage}
        setPreviousPage={setPreviousPage}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={setRowsPerPage}
      />
    </div>
  );
};

export default Business;
