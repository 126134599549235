import React from "react";

export interface SettlementsData {
  businessName: string;
  settlementAmount: string;
  settlementFee: string;
  currency: string;
  settlementCycle: string;
  status: React.ReactNode;
  action: React.ReactNode;
}

export const ColumnSettlements = [
  {
    Header: "Business name",
    accessor: "businessName",
  },
  {
    Header: "Settlement amount",
    accessor: "settlementAmount",
  },
  {
    Header: "Transaction fee",
    accessor: "settlementFee",
  },
  {
    Header: "Currency",
    accessor: "currency",
  },
  {
    Header: "Settlement cycle",
    accessor: "settlementCycle",
  },
  {
    Header: "Status",
    accessor: "status",
  },
  {
    Header: "Action",
    accessor: "action",
  },
];
