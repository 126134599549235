import React from 'react';

export interface SettlementsReconciliationData {
	transactionId: string;
	currency: string;
	amount: string;
	reference: string;
	businessName: string;
	country: string;
	date: string;
}

export const ColumnSettlementsReconciliation = [
	{
		Header: 'Transaction ID',
		accessor: 'transactionId',
	},
	{
		Header: 'Currency',
		accessor: 'currency',
	},
	{
		Header: 'Amount',
		accessor: 'amount',
	},
	{
		Header: 'PG reference',
		accessor: 'reference',
	},
	{
		Header: 'Business name',
		accessor: 'businessName',
	},
	{
		Header: 'Country',
		accessor: 'country',
	},
	{
		Header: 'Transaction date',
		accessor: 'date',
	},
];

export interface SettlementsReconciliationSData {
	merchantId: string;
	businessName: string;
	volume: string;
	value: string;
	transactionId: string;
	date: string;
}

export const ColumnSettlementsReconciliationS = [
	{
		Header: 'Merchant ID',
		accessor: 'merchantId',
	},
	{
		Header: 'Business name',
		accessor: 'businessName',
	},
	{
		Header: 'Transaction volume',
		accessor: 'volume',
	},
	{
		Header: 'Transaction value',
		accessor: 'value',
	},
	{
		Header: 'Transaction ID',
		accessor: 'transactionId',
	},
	{
		Header: 'Transaction date',
		accessor: 'date',
	},
];

export interface SettlementsReconciliationFData {
	transactionId: string;
	cost: string;
	providerName: string;
	providerPlatform: string;
	amount: string;
	currency: string;
}

export const ColumnSettlementsReconciliationF = [
	{
		Header: 'Transaction ID',
		accessor: 'transactionId',
	},
	{
		Header: 'Total cost',
		accessor: 'cost',
	},
	{
		Header: 'Provider name',
		accessor: 'providerName',
	},
	{
		Header: 'Provider platform',
		accessor: 'providerPlatform',
	},
	{
		Header: 'Total amount processed',
		accessor: 'amount',
	},
	{
		Header: 'Currency',
		accessor: 'currency',
	},
];
