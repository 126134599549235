import React, { useEffect, useState } from 'react';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { useHistory, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { closeLoader, openLoader } from '../../../redux/actions/loader/loaderActions';
import client from '../../../api/client';
import { openToastAndSetContent } from '../../../redux/actions/toast/toastActions';
import { ToastErrorStyles } from '../../../constants/toastStyles';
import { numberWithCommas } from '../../../utils/formatNumber';
import moment from 'moment';

const RefundDetails = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    const { id } = useParams<{ id: any }>();
    const [refund, setRefund] = useState<any>();
    const [refundDetails, setRefundDetails] = useState<any[]>([]);

    const fetchRefundDetails = async () => {
        dispatch(openLoader());
        try {
            const { data }: any = await client.get(`/refund/all/paginated?id=${id}`);
            setRefund(data?.data.items[0]);
            dispatch(closeLoader());
        } catch (error: any) {
            dispatch(closeLoader());
            if (error?.response?.data?.message === 'Please login again') {
                sessionStorage.clear();
                window.location.href = '/';
            } else {
                dispatch(
                    openToastAndSetContent({
                        toastContent: error?.response?.data?.message,
                        toastStyles: ToastErrorStyles,
                    })
                );
            }
        }
    };

    useEffect(() => {
        fetchRefundDetails();
    }, []);

    useEffect(() => {
        if (refund) {
            setRefundDetails([
                { label: 'Transaction ID', value: refund.id },
                { label: 'Payment Type', value: refund.paymentType },
                { label: 'Order Reference', value: refund.orderReference },
                { label: 'Refund Reference', value: refund.refundReference },
                { label: 'Currency', value: refund.currency },
                { label: 'Refund Amount', value: numberWithCommas(refund.refundAmount) },
                { label: 'Total Amount', value: numberWithCommas(refund.amount) },
                { label: 'Fee', value: numberWithCommas(refund.fee) },
                { label: 'Customer Name', value: refund.customerName },
                { label: 'Customer Email', value: refund.customerEmail ?? 'Not Available' },
                { label: 'Status', value: refund.refundStatus },
                { label: 'Provider Response Message', value: refund.providerResponseMessage },
                { label: 'Provider Response Code', value: refund.providerResponseCode },
                { label: 'Provider Receipt', value: refund.providerReceipt },
                { label: 'Processor Provider Bank', value: refund.processorProviderBank },
                { label: 'Processor Provider UserName', value: refund.processorProviderUserName },
                { label: 'Date Created', value: moment(refund.dateCreated).format('DD-MMM-YYYY hh:mm:ss a') },
                { label: 'Original Transaction Date', value: moment(refund.orderDateCreated).format('DD-MMM-YYYY hh:mm:ss a') },
            ]);
        }
    }, [refund]);

    return (
        <div className="container my-4">
            <div className="mb-3 d-flex align-items-center" style={{ cursor: 'pointer' }} onClick={() => history.goBack()}>
                <ArrowBackIosIcon sx={{ fontSize: '20px', color: '#211F01' }} />
                <span className="ms-2 fw-bold text-dark">Refund Details</span>
            </div>

            {refund && (
                <div className="card shadow-sm border-0 rounded-3 p-4">
                    <h5 className="card-title fw-bold mb-3">Refund Details</h5>
                    <div className="card-body">
                        {refundDetails.map((item, index) => (
                            <div key={index} className="row mb-3 align-items-center">
                                <div className="col-6 fw-semibold text-dark">{item.label}</div>
                                <div className="col-6 text-end text-secondary">{item.value}</div>
                                {index < refundDetails.length - 1 && <hr className="my-2" />}
                            </div>
                        ))}
                    </div>
                </div>
            )}
        </div>
    );
};

export default RefundDetails;
